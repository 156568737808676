<template>
  <div class="shopping">
    <Header></Header>
    <Nav></Nav>
    <!-- 机床商城开始 -->
    <el-row class="device-box">
      <el-row class="contaner">
        <!-- 面包屑 start -->
        <el-row class="nav-box" type="flex" align="middle">
          <!-- <span class="pos">您现在的位置：</span> -->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item
              ><span class="c3">配件商城</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
        <!-- 面包屑 end -->
        <!-- 轮播图 开始 -->
        <el-row class="lunbo">
          <el-row class="contaner">
            <div class="market-lunbo-box">
              <div class="con">
                <div class="marke-lunbo-left">
                  <div class="one-box">
                    <div
                      v-for="(item, index) in list"
                      :key="index"
                      :class="['one', listId == index ? 'active' : '']"
                    >
                      <span>{{ item.one }}</span>
                      <img src="@/assets/images/rigth.png" alt="" />
                    </div>
                  </div>
                </div>
                <div class="two-box">
                  <div class="two">
                    <div class="two-item" v-for="n in 100" :key="n">
                      11122222222223333333333333344
                    </div>
                  </div>
                </div>
              </div>

              <el-carousel height="516px" arrow="never" loop autoplay>
                <el-carousel-item
                  v-for="(item, index) in rotationList"
                  :key="index"
                >
                  <img :src="item.imgUrl" alt="" />
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-row>
        </el-row>
        <!-- 轮播图 结束 -->

        <!-- 热门类目 开始 -->
        <!-- <el-row class="hot-list">
          <el-row class="header" type="flex" justify="space-between">
            <el-row type="flex" align="middle" class="title">
              <img src="@/assets/images/shopping/hot.png" alt="" />
              <span>热门类目</span>
            </el-row>
            <el-row type="flex" align="middle" class="more">
              <span>
                <router-link :to="{ name: 'shoppinglist' }"
                  >查看更多</router-link
                >
              </span>
              <i class="el-icon-arrow-right"></i>
            </el-row>
          </el-row>
          <el-row class="contant">
            <el-row class="left" v-if="sortLeftList.length">
              <el-tabs
                :tab-position="tabPosition"
                style="height: 448px"
                @tab-click="changeLeftTab"
              >
                <el-tab-pane
                  v-for="(item, index) in sortLeftList"
                  :label="item.categoryTitle"
                  :key="index"
                >
                  <el-row class="good">
                    <div v-if="sortGoodLeft.length">
                      <div
                        class="item"
                        v-for="(item, index) in sortGoodLeft"
                        :key="index"
                      >
                        <router-link
                          :to="{ name: 'detail', query: { id: item.id } }"
                        >
                          <img :src="item.mainImg" alt="" />
                          <p class="text">{{ item.briefTitle }}</p>
                          <p class="price">
                            {{
                              item.isNegotiable
                                ? "面议"
                                : "¥" + item.showPrice.toFixed(2)
                            }}
                          </p>
                        </router-link>
                      </div>
                    </div>
                    <el-empty
                      style="width: 100%"
                      v-else
                      description="暂无数据"
                    ></el-empty>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-row>
            <el-row class="right" v-if="sortRightList.length">
              <el-tabs
                :tab-position="tabPosition"
                style="height: 448px"
                @tab-click="changeRightTab"
              >
                <el-tab-pane
                  v-for="(item, index) in sortRightList"
                  :label="item.categoryTitle"
                  :key="index"
                >
                  <el-row class="good">
                    <div v-if="sortGoodRight.length">
                      <el-row
                        class="item"
                        v-for="(item, index) in sortGoodRight"
                        :key="index"
                      >
                        <router-link
                          :to="{ name: 'detail', query: { id: item.id } }"
                        >
                          <img :src="item.mainImg" alt="" />
                          <p class="text">{{ item.briefTitle }}</p>
                          <p class="price">
                            {{
                              item.isNegotiable
                                ? "面议"
                                : "¥" + item.showPrice.toFixed(2)
                            }}
                          </p>
                        </router-link>
                      </el-row>
                    </div>
                    <el-empty
                      style="width: 100%"
                      v-else
                      description="暂无数据"
                    ></el-empty>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-row>
            <el-empty
              style="width: 100%"
              v-if="!(sortLeftList.length && sortRightList.length)"
              description="暂无分类"
            ></el-empty>
          </el-row>
        </el-row> -->
        <!-- 热门类目 结束 -->
        <!-- 配件商城列表 -->
        <router-link :to="{ name: 'marketlist' }">
          <p class="price">配件商城列表</p>
        </router-link>
        <!-- 精选机床 开始 -->
        <!-- banner -->
        <el-row class="banner-box" id="banner-box-id">
          <el-row class="banner">
            <p class="line1">
              <img src="@/assets/images/shopping/right.png" alt="" />
              <img src="@/assets/images/shopping/right.png" alt="" />
              <span>精品推荐</span>
              <img src="@/assets/images/shopping/left.png" alt="" />
              <img src="@/assets/images/shopping/left.png" alt="" />
            </p>
            <p class="line2">Selected machine tool</p>
          </el-row>
          <!-- 列表 -->
          <el-row class="banner-list">
            <el-row class="good" v-if="goodList.length">
              <el-row
                class="item"
                v-for="(item, index) in goodList"
                :key="index"
              >
                <router-link
                  :to="{ name: 'marketdetail', query: { id: item.id } }"
                >
                  <img :src="item.mainImg" alt="" />
                  <p class="text">{{ item.briefTitle }}</p>
                  <p class="price">
                    {{
                      item.isNegotiable
                        ? "面议"
                        : "¥" + item.showPrice.toFixed(2)
                    }}
                  </p>
                </router-link>
              </el-row>
            </el-row>
            <el-empty
              style="width: 100%"
              v-else
              description="暂无数据"
            ></el-empty>

            <div
              v-if="goodList.length >= total"
              class="c9"
              style="text-align: center; margin-top: 20px; margin-bottom: 20px"
            >
              无更多数据
            </div>
          </el-row>
        </el-row>
        <!-- 精选机床 结束 -->
        <!-- 加载更多 -->
        <el-row
          class="loading-more"
          v-if="loading"
          v-loading="loading"
          element-loading-text="正在加载"
          element-loading-background="transparent"
          element-loading-target="loading-more"
          element-loading-spinner="el-icon-loading"
        >
        </el-row>
      </el-row>
    </el-row>
    <!-- 机床商城結束 -->
    <Footer></Footer>
    <!-- 合作  回收  -->
    <el-row class="fix-right">
      <!-- 回收 -->
      <el-row class="item1">
        <el-button type="text" @click="handleRecoveryShow">
          <img src="@/assets/images/home/recovery.png" alt="" />
          <p>机床回收</p>
        </el-button>
      </el-row>
      <!-- 合作 -->
      <el-row class="item2">
        <el-button type="text" @click="handleTogetherShow">
          <img src="@/assets/images/home/together.png" alt="" />
          <p>商务合作</p>
        </el-button>
      </el-row>
      <!-- 客服 -->
      <el-row class="item3">
        <el-button type="text" @click="assderVisible = true">
          <img src="@/assets/images/home/kefu.png" alt="" />
          <p class="cf">联系客服</p>
        </el-button>
      </el-row>
      <!-- 联系客服 -->
      <!-- 合作  回收 -->
    </el-row>
    <!-- 机床回收弹窗 -->
    <el-dialog
      top="0"
      key="recovery"
      :visible.sync="recoveryShow"
      width="600px"
      :before-close="handleRecoveryClose"
      :show-close="false"
      custom-class="recovery-box"
    >
      <!-- 头部 -->
      <el-row
        class="header p32"
        type="flex"
        justify="space-between"
        align="center"
      >
        <div class="left">
          <img src="@/assets/images/recovery/line.png" alt="" />
          <span class="c16 f3">机床回收</span>
        </div>
        <div class="right" @click="recoveryShow = false">
          <img src="@/assets/images/recovery/close.png" alt="" />
        </div>
      </el-row>
      <!-- 内容 -->
      <div class="content">
        <div class="logo">
          <img src="@/assets/images/recovery/logo.png" alt="" class="img1" />
          <img
            src="@/assets/images/recovery/hengxian.png"
            alt=""
            class="img2"
          />
          <p class="cf logo-text">高价回收 上门服务 方便快捷</p>
        </div>
        <div class="form-box">
          <div class="form-content">
            <p class="title c3 f16 bold">填写回收信息</p>
            <el-form
              key="recovery"
              label-width="82px"
              :model="ruleForm"
              :status-icon="false"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
              :hide-required-asterisk="false"
            >
              <!-- 姓名 -->
              <el-form-item label="姓名:" required prop="name">
                <el-input
                  key="1"
                  type="text"
                  maxlength="10"
                  v-model="ruleForm.name"
                  placeholder="请输入姓名"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 姓名结束 -->
              <!-- 联系 -->
              <el-form-item label="联系方式:" required prop="phone">
                <el-input
                  type="phone"
                  maxlength="11"
                  v-model="ruleForm.phone"
                  placeholder="请输入联系方式"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 联系方式 -->
              <!-- 单位名称 -->
              <el-form-item label="单位名称:" required prop="company">
                <el-input
                  type="text"
                  maxlength="20"
                  v-model="ruleForm.company"
                  placeholder="请输入单位名称"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 单位名称 -->
              <!-- 我的需求: -->
              <el-form-item class="mb20" label="我的需求:" prop="need">
                <el-input
                  type="textarea"
                  maxlength="300"
                  v-model="ruleForm.need"
                  placeholder="请输入我的需求"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 我的需求:结束 -->
              <!-- 提交开始 -->
              <el-form-item>
                <el-button class="btn cf f16" @click="submitForm('ruleForm', 1)"
                  >提交</el-button
                >
              </el-form-item>
              <!-- 提交结束 -->
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 机床回收对话框结束 -->
    <!-- 商务合作弹窗 -->
    <el-dialog
      top="0"
      key="together"
      :visible.sync="togetherShow"
      width="600px"
      :before-close="handleTogetherClose"
      :show-close="false"
      custom-class="recovery-box"
    >
      <!-- 头部 -->
      <el-row
        class="header p32"
        type="flex"
        justify="space-between"
        align="center"
      >
        <div class="left">
          <img src="@/assets/images/recovery/line.png" alt="" />
          <span class="c16 f3">商务合作</span>
        </div>
        <div class="right" @click="togetherShow = false">
          <img src="@/assets/images/recovery/close.png" alt="" />
        </div>
      </el-row>
      <!-- 内容 -->
      <div class="content">
        <div class="logo">
          <img src="@/assets/images/recovery/logo.png" alt="" class="img1" />
          <img
            src="@/assets/images/recovery/hengxian.png"
            alt=""
            class="img2"
          />
          <p class="cf logo-text">高价回收 上门服务 方便快捷</p>
        </div>
        <div class="form-box">
          <div class="form-content">
            <p class="title c3 f16 bold">填写商务合作信息</p>
            <el-form
              key="together"
              label-width="82px"
              :model="ruleForm"
              :status-icon="false"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
              :hide-required-asterisk="false"
            >
              <!-- 姓名 -->
              <el-form-item label="姓名:" required prop="name">
                <el-input
                  key="2"
                  type="text"
                  maxlength="10"
                  v-model="ruleForm.name"
                  placeholder="请输入姓名"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 姓名结束 -->
              <!-- 联系 -->
              <el-form-item label="联系方式:" required prop="phone">
                <el-input
                  type="phone"
                  maxlength="11"
                  v-model="ruleForm.phone"
                  placeholder="请输入联系方式"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 联系方式 -->
              <!-- 单位名称 -->
              <el-form-item label="单位名称:" required prop="company">
                <el-input
                  type="text"
                  maxlength="20"
                  v-model="ruleForm.company"
                  placeholder="请输入单位名称"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 单位名称 -->
              <!-- 我的需求: -->
              <el-form-item class="mb20" label="我的需求:" prop="need">
                <el-input
                  type="textarea"
                  v-model="ruleForm.need"
                  maxlength="300"
                  placeholder="请输入我的需求"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <!-- 我的需求:结束 -->
              <!-- 提交开始 -->
              <el-form-item>
                <el-button class="btn cf f16" @click="submitForm('ruleForm', 2)"
                  >提交</el-button
                >
              </el-form-item>
              <!-- 提交结束 -->
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 商务合作结束 -->
    <!-- 客服弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="600px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">联系平台</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p>
          <img src="@/assets/images/home/phone.png" alt="" />
          <span>{{ showConText.officialTel }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/weixin.png" alt="" />
          <span>{{ showConText.officialWeChat }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/qq.png" alt="" />
          <span>{{ showConText.officialQq }} </span>
        </p>
        <p>
          <img src="@/assets/images/home/email.png" alt="" />
          <span>{{ showConText.officialMail }} </span>
        </p>
      </div>
    </el-dialog>
    <!-- 客服弹窗结束 -->
  </div>
</template>

<script>
let flag = true;
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangShopping",
  components: {
    Header,
    Nav,
    Footer,
  },
  data() {
    // var validateName = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入姓名"));
    //   }
    //   callback();
    // };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
          callback(new Error("手机号格式不正确"));
        }
      }
      callback();
    };
    // var validateCompany = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入单位名称"));
    //   }
    //   callback();
    // };
    // var validateNeed = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入需求"));
    //   }
    //   callback();
    // };
    return {
      listId: 0,
      list: [
        {
          one: "一级",
        },
        {
          one: "一级",
        },
      ],
      assderVisible: false, //联系客服弹窗
      recoveryShow: false, //机床回收弹窗
      togetherShow: false, //商务合作弹窗
      loading: true, //是否正在加载
      images: [
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
        require("@/assets/images/home/lunbo1.png"),
      ], //轮播图片
      tabPosition: "left", //tab

      ruleForm: {
        name: "", //姓名
        phone: "", //联系方式
        company: "", //公司
        need: "", //我的需求
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur", "change"],
          },
        ],
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        company: [
          {
            required: true,
            message: "请输入单位名称",
            trigger: ["blur", "change"],
          },
        ],
        need: [
          {
            required: true,
            message: "请输入我的需求",
            trigger: ["blur", "change"],
          },
        ],
      }, //机床回收表单数据

      ruleForm2: {
        name: "", //姓名
        phone: "", //联系方式
        company: "", //公司
        need: "", //我的需求
      },
      rules2: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["blur", "change"],
          },
        ],
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        company: [
          {
            required: true,
            message: "请输入单位名称",
            trigger: ["blur", "change"],
          },
        ],
        need: [
          {
            required: true,
            message: "请输入我的需求",
            trigger: ["blur", "change"],
          },
        ],
      }, //机床回收表单数据

      sortList: [], //所有分类
      sortLeftList: [], //左侧分类
      sortRightList: [], //右侧分类
      sortGoodLeft: [], //左侧分类对应商品
      sortGoodRight: [], //右侧分类对应商品
      // 查询分类商品列表
      pageNo: 1,
      pageSize: 6,
      categoryOneId: "", //分类id
      goodList: [], //商品列表
      page: 1, //滚动加载页数
      total: 0, //总条数
      pageAllSize: 5, //下方列表每次请求条数

      rotationList: [], //轮播

      showConText: {}, //qq微信邮箱
    };
  },

  mounted() {},
  created() {
    // 监听滚动
    window.addEventListener("scroll", () => {
      if (localStorage.getItem("scrollPagePath") === "/shopping") {
        // console.log("shopping");
        this.onScroll();
      }
    });
    // 二手商品-查询机床类目
    this.getGoodSort();

    // 二手商品的列表
    this.pageSize = 5;
    this.getAllGoodList();

    // 轮播
    this.getRotation();

    //根据分组查询系统配置qq微信邮箱
    this.getConfig();
  },

  methods: {
    //根据分组查询系统配置
    getConfig() {
      let paramData = {
        configGroup: "tellUs",
      };
      this.$API
        .getConfig(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 轮播图列表查询
    getRotation() {
      let params = {
        bannerType: 2,
        pageNo: 1,
        pageSize: 100,
      };
      this.$API
        .getRotation(params)
        .then((res) => {
          if (res.code == 1) {
            this.rotationList = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 页面滚动处理函数
    onScroll() {
      // 底部的列表盒子
      let caseBoxDom = document.querySelector("#banner-box-id");
      let { height: caseBoxHeight, top: caseBoxTop } =
        caseBoxDom.getBoundingClientRect();
      let outerHeight = document.documentElement.clientHeight;

      if (
        caseBoxHeight + caseBoxTop <= outerHeight + 100 &&
        caseBoxHeight + caseBoxTop > outerHeight
      ) {
        // console.log("快到底部了");
        // console.log("列表长度", this.goodList.length);
        // console.log("总长度", this.total);

        if (this.goodList.length < this.total) {
          console.log("要在请求数据了");
          this.page += 1;
          this.getAllGoodList();
        }
      }
    },

    // 所有二手商品列表
    getAllGoodList() {
      this.loading = true;
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageAllSize,
        nominateFlag: 1,
      };
      this.$API
        .getGoodList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.total = res.data.total;
            this.goodList = this.goodList.concat(res.data.records);
            // this.goodList = [];
            // console.log(this.goodList, res.data.records);
          } else {
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // 左侧tab改变
    changeLeftTab(e) {
      let id;
      this.sortLeftList.forEach((item) => {
        if (item.categoryTitle == e.label) {
          id = item.id;
        }
      });
      this.categoryOneId = id;
      this.pageNo = 1;
      this.pageSize = 6;
      this.getGoodList((list) => {
        this.sortGoodLeft = list;
      });
    },
    // 右侧tab改变
    changeRightTab(e) {
      let id;
      this.sortRightList.forEach((item) => {
        if (item.categoryTitle == e.label) {
          id = item.id;
        }
      });
      this.categoryOneId = id;
      this.pageNo = 1;
      this.pageSize = 6;
      this.getGoodList((list) => {
        this.sortGoodRight = list;
      });
    },
    //客服弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 商务合作
    handleTogetherClose() {
      this.togetherShow = false;
    },
    // 机床回收
    handleRecoveryClose() {
      this.ruleForm.name = "";
      this.ruleForm.phone = "";
      this.ruleForm.need = "";
      this.ruleForm.company = "";
      this.recoveryShow = false;
    },

    // 打开机床回收弹窗
    //  this.$refs[formName].resetFields();
    // recoveryShow = true
    // togetherShow = true
    handleRecoveryShow() {
      // if (!this.$store.state.token) {
      //   this.$router.push({ name: "login" });
      //   return;
      // }
      this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
      this.recoveryShow = true;
    },

    // 打开合作弹窗
    //  this.$refs[formName].resetFields();
    // recoveryShow = true
    // togetherShow = true
    handleTogetherShow() {
      // if (!this.$store.state.token) {
      //   this.$router.push({ name: "login" });
      //   return;
      // }
      this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
      this.togetherShow = true;
    },

    // 机床回收提交
    submitForm(formName, type) {
      if (!flag) {
        return;
      }
      // console.log(type);
      // return;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          flag = false;
          // 关闭机床回收弹窗
          // this.ruleForm.name = "";
          // this.ruleForm.phone = "";
          // this.ruleForm.need = "";
          // this.ruleForm.company = "";
          // this.recoveryShow = false;
          let paramData;
          if (type == 1) {
            paramData = {
              intentionType: 1,
              fullName: this.ruleForm.name,
              mobile: this.ruleForm.phone,
              companyName: this.ruleForm.company,
              remarks: this.ruleForm.need,
            };
          }
          if (type == 2) {
            paramData = {
              intentionType: 2,
              fullName: this.ruleForm.name,
              mobile: this.ruleForm.phone,
              companyName: this.ruleForm.company,
              remarks: this.ruleForm.need,
            };
          }

          // console.log(paramData);
          // return;
          this.$API
            .recovery(paramData)
            .then((res) => {
              if (res.code == 1) {
                flag = true;
                this.$message({
                  message: "提交成功",
                  type: "success",
                });

                setTimeout(() => {
                  if (type == 1) {
                    this.recoveryShow = false;
                  }
                  if (type == 2) {
                    this.togetherShow = false;
                  }
                }, 500);
                setTimeout(() => {
                  // this.ruleForm.name = "";
                  // this.ruleForm.phone = "";
                  // this.ruleForm.need = "";
                  // this.ruleForm.company = "";
                  this.$refs[formName].resetFields();
                }, 600);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });

          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 商务合作提交
    submitForm2(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 关闭机床回收弹窗
          // this.ruleForm.name = "";
          // this.ruleForm.phone = "";
          // this.ruleForm.need = "";
          // this.ruleForm.company = "";
          // this.recoveryShow = false;
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 二手商品列表
    getGoodList(cb) {
      this.loading = true;
      let categoryOneId = this.categoryOneId ? Number(this.categoryOneId) : "";
      let paramData = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        categoryOneId,
      };
      this.$API
        .getGoodList(paramData)
        .then((res) => {
          if (res.code == 1) {
            cb(res.data.records);
          } else {
            this.loading = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 二手商品-查询机床类目
    getGoodSort() {
      let params = {
        categoryType: 2,
        parentId: 0,
      };
      this.$API
        .getGoodSort(params)
        .then((res) => {
          if (res.code == 1) {
            this.sortList = res.data;
            // this.sortList = this.sortList.concat(this.sortList);
            // console.log(this.sortList);
            // this.sortList = [];
            //将分类拆分为左右两部分分别展示在两侧
            this.sortLeftList = this.sortList.slice(
              0,
              Math.ceil(this.sortList.length / 2)
            );
            this.sortRightList = this.sortList.slice(
              Math.ceil(this.sortList.length / 2)
            );
            // this.sortRightList = [];
            //设置分类左侧对应商品信息
            this.categoryOneId = this.sortLeftList[0].id;
            this.pageSize = 6;
            this.getGoodList((list) => {
              this.sortGoodLeft = list;
            });
            //设置分类右侧对应商品信息
            this.categoryOneId = this.sortRightList[0].id;
            this.getGoodList((list) => {
              this.sortGoodRight = list;
            });
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  // 设置不用分类下展示商品
  setSortList() {},
};
</script>

<style lang="scss">
.device-box {
  border-top: 1px solid #ed1b23;
  min-width: 1200px;
  width: 100%;
  background: #f8f8f8;
  .contaner {
    background: #f8f8f8;
  }
  .nav-box {
    padding-top: 36px;
    .pos {
      color: #333333;
      font-size: 16px;
    }
  }
}
// 轮播
.lunbo {
  margin-top: 24px;
  .contaner {
    width: 100% !important;
  }
  .el-carousel__item {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.hot-list {
  margin-top: 43px;
  .title {
    cursor: pointer;
    height: 26px;
    img {
      width: 23px;
      height: 24px;
      margin-right: 10px;
    }
    span {
      font-size: 20px;
      color: #000;
    }
  }
  .more {
    cursor: pointer;
    color: #333;
    font-size: 14px;
  }
  .contant {
    .left {
      width: 580px;
      height: 448px;
      display: inline-block;
      margin-right: 40px;
    }
    .right {
      width: 580px;
      height: 448px;
      display: inline-block;
    }
    height: 448px;
    margin-top: 24px;
    .tab {
      width: 160px;
      height: 448px;
      background-image: url("../assets/images/shopping/banner.png");
      background-size: 160px 448px;
    }
    .el-tabs--left {
      position: absolute;
      z-index: 100;
      top: 60px;
      text-align: center;
      left: 0;
      cursor: pointer;
    }
    .el-tabs__item {
      width: 135px;
      box-sizing: border-box;
      padding: 0 24px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      background: #ffffff;
      border-radius: 20px 20px 20px 20px;
      font-size: 16px;
      color: #000000;
      white-space: nowrap;
      margin-bottom: 32px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .el-tabs--left {
      top: 0px !important;
    }
    .is-active {
      background-color: #ed1b23;
      color: #ffffff;
    }
    .el-tabs--left .el-tabs__nav-wrap.is-left::after {
      height: 0px;
    }
    .el-tabs__active-bar {
      height: 0px !important;
    }
    .el-tabs__header {
      padding-top: 60px;
      box-sizing: border-box;
      width: 160px;
      display: flex;
      justify-content: center;
      width: 160px;
      height: 448px;
      background-image: url("../assets/images/shopping/banner.png");
      background-size: 160px 448px;
    }
    .el-tabs__header.is-left {
      margin-right: 0px;
    }
    .el-tabs__content {
    }
    .good {
      width: 420px;
      height: 448px;
      box-sizing: border-box;
      background-color: #f8f8f8;
      display: flex;
      // justify-content: space-between;
      .item {
        display: inline-block;
        width: 120px;
        height: 224px;
        // background: #ffffff;
        // border: 1px solid #f0f0f0;
        margin-left: 20px;
        margin-bottom: 24px;
        cursor: pointer;
        transition: box-shadow 0.03s;
        &:hover {
          box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
        }
        img {
          width: 120px;
          height: 120px;
        }
        .text {
          @include dot(2);
          margin-top: 6px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          // margin-left: 12px;
          margin-right: 5px;
          font-size: 14px;
          text-align: left;
          padding-left: 10px;
          height: 42px;
        }
        .price {
          margin-top: 8px;
          font-size: 16px;
          color: #ed1b23;
          line-height: 25px;
          // margin-left: 12px;
          margin-right: 5px;
          text-align: left;
          padding-left: 10px;
        }
      }
    }
  }
}
// 精选机床
.banner-box {
  .banner {
    width: 1200px;
    height: 96px;
    margin-top: 44px;
    background-image: url("../assets/images/shopping/banner1.png");
    background-size: 1200px 96px;
    p {
      text-align: center;
      img {
        width: 19px;
        height: 19px;
      }
    }
    .line1 {
      line-height: 19px;
      margin-top: 22px;
      margin-bottom: 6px;
      span {
        font-size: 24px;
        color: #333333;
        margin: 0 32px;
      }
    }
  }
  .good {
    width: 100%;
    // height: 741px;
    box-sizing: border-box;
    padding: 24px;
    padding-bottom: 0px;
    background-color: #ffffff;
    .item {
      display: inline-block;
      // width: 273px;
      // height: 334px;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      margin-right: 17px;
      margin-bottom: 24px;
      cursor: pointer;
      transition: box-shadow 0.03s;
      padding-bottom: 16px;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      &:hover {
        box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
      }
      img {
        width: 214px;
        height: 192px;
      }
      .text {
        width: 190px;
        height: 50px;
        @include dot(2);
        // width: 200px;
        margin-top: 12px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        margin-left: 12px;
        margin-right: 5px;
        // padding-left: 5px;
      }
      .price {
        margin-top: 16px;
        font-size: 20px;
        color: #ed1b23;
        line-height: 25px;
        margin-left: 12px;
        margin-right: 5px;
      }
    }
  }
}
// // 加载更多
.loading-more {
  width: 100%;
  height: 174px;
  margin: 0 auto;
}
// 右侧回收 合作
.fix-right {
  cursor: pointer;
  width: 88px;
  height: 180px;
  border: 1px solid #ebebeb;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  .item1,
  .item2 {
    // background-color: #ffffff;
    border-bottom: 1px solid #ebebeb;
  }
  .item2 {
    // background-color: #ed1b23;
  }
  .item1,
  .item2,
  .item3 {
    height: 90px;
    text-align: center;
    box-sizing: border-box;
    padding-top: 9px;
    img {
      width: 24px;
      height: 24px;
    }
    p {
      color: #757575;
      font-size: 14px;
      margin-top: 12px;
    }
  }
  .item3 {
    margin-top: 57px;
    background-color: #ed1b23;
    color: #ffffff;
    p {
      color: #ffffff;
    }
  }
}
// 机床回收对话框
.recovery-box {
  top: 50% !important;
  transform: translateY(-50%);

  // 对话框头部
  .el-dialog__header {
    padding: 0px !important;
  }
  .header {
    padding-top: 13px;
    margin-bottom: 11px;
    .left {
      img {
        width: 4px;
        height: 14px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
    .right {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  // 对话框内容
  .el-dialog__body {
    padding: 0px !important;
  }
  // 外层对话框定位

  .content {
    width: 600px;
    height: 713px;
    background-image: url("../assets/images/recovery/bg.png");
    background-size: 600px 713px;
    position: relative;
    .logo {
      position: absolute;
      top: 37px;
      left: 34px;
      .img1 {
        width: 319px;
        height: 63px;
        margin-bottom: 21px;
      }
      .img2 {
        width: 319px;
        height: 36px;
      }
      .logo-text {
        position: absolute;
        left: 10px;
        top: 90px;
        font-size: 21px;
        font-weight: 500;
      }
    }
    // 表单区
    .form-box {
      width: 520px;
      height: 474px;
      background-image: url("../assets/images/recovery/form.png");
      background-size: 520px 474px;
      position: absolute;
      top: 197px;
      left: 40px;
      .btn {
        width: 140px;
        height: 42px;
        background: linear-gradient(132deg, #ed1b23 0%, #f15f64 100%);
        box-shadow: 0px 4px 26px 1px rgba(237, 27, 35, 0.2);
        border-radius: 8px 8px 8px 8px;
        margin-top: 10px;
        margin-left: 72px;
        color: #ffffff;
        font-size: 16px;
      }
      .form-content {
        width: 520px;
        height: 474px;
        position: absolute;
        top: 43px;
        left: 47px;
        .demo-ruleForm {
          margin-top: 24px;
        }
        .el-input__inner {
          width: 345px;
          height: 40px;
          background: #f8f8f8;
          border-radius: 4px 4px 4px 4px;
        }
        .el-form-item__label {
          text-align: left;
        }
        .el-textarea__inner {
          width: 345px;
          height: 92px;
          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
        }
      }
      .title {
        text-align: center;
        position: relative;
        left: -52px;
      }
    }
  }
}
// 机床回收弹窗样式重置
.shopping-contaner {
  .el-dialog {
    margin: 0px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translateX(-50%) translateY(-50%) !important;
  }
}
// 右侧保修 客服
// .fix-right {
//   cursor: pointer;
//   width: 88px;
//   height: 180px;
//   border: 1px solid #ebebeb;
//   position: fixed;
//   z-index: 1000;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   .item1 {
//     background-color: #ffffff;
//   }
//   .item2 {
//     background-color: #ed1b23;
//   }
//   .item1,
//   .item2 {
//     height: 90px;
//     text-align: center;
//     box-sizing: border-box;
//     padding-top: 9px;
//     img {
//       width: 24px;
//       height: 24px;
//     }
//     p {
//       color: #757575;
//       font-size: 14px;
//       margin-top: 12px;
//     }
//   }
//   .item2 p {
//     color: #ffffff;
//   }
// }
// 自定义弹窗样式
.assderTc {
  display: flex;
  align-items: center;
  .title {
    padding-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #333333;
    img {
      width: 32px;
      height: 32px;
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog {
    border-radius: 0 !important;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-dialog__footer {
    padding: 0;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .ja-c {
    height: 311px;
    box-sizing: border-box;
    padding-left: 194px;
    padding-top: 52px;
    p {
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: #333333;
        margin-left: 16px;
      }
    }
  }
}

.market-lunbo-box {
  display: flex;

  .lunbo,
  .contaner,
  .el-carousel {
    width: 954px !important;
  }

  .marke-lunbo-left {
    width: 230px;
    height: 516px;
    background: #ffffff;
    margin-right: 16px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .one-box {
    width: 250px;
    height: 516px;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .one {
    cursor: pointer;
    box-sizing: border-box;
    width: 230px;
    height: 62px;
    display: flex;
    background: #ffffff;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;

    span {
      width: 130px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 20px;
      font-size: 16px;
    }

    img {
      width: 6px;
      height: 10px;
    }
  }

  .one:hover {
    background: rgba(238, 238, 238, 0.5);
  }

  .active {
    color: #333333;
    font-weight: bold;
    background: #eeeeee !important;
  }

  .con {
    position: relative;
  }

  .two-box {
    position: absolute;
    left: 230px;
    top: 0px;
    z-index: 10;
    width: 400px;
    overflow: hidden;
    display: none;
  }

  .two {
    // position: absolute;
    width: 420px;
    height: 315px;

    overflow-x: hidden;
    overflow-y: scroll;
    background: #fff;
    // left: 230px;
    // top: 0px;
    // z-index: 10;
    box-sizing: border-box;
    padding: 24px;
    color: #666666;
    display: flex;
    flex-wrap: wrap;
    padding-right: 14px;
  }

  .two-item {
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 7px;
    margin-bottom: 32px;
    cursor: pointer;
  }

  .marke-lunbo-left:hover + .two-box {
    display: block !important;
  }

  .two-box:hover {
    display: block !important;
  }
}
</style>
